$(document).ready(function() {
  const api = new AjaxComponent();

  // GETリクエスト
  api.get('/user')
    .then(data => {
      // 初期ロード時のユーザー情報表示
      $('#userId').text(data.userId);
      $('#userName').text(data.userName);
      $('#passwordValue').text('・・・・・・・・');

      // パスワードの表示/非表示を切り替える処理
      $('#toggleMask').click(function() {
        if ($('#passwordValue').text().startsWith('・')) {
          $('#passwordValue').text(data.password);
          $('#passwordValue').addClass('is-active');
          $('#toggleMask img').attr('src', '../assets/images/icon/icon-eye_off.svg');
        } else {
          $('#passwordValue').text('・・・・・・・・');
          $('#passwordValue').removeClass('is-active');
          $('#toggleMask img').attr('src', '../assets/images/icon/icon-eye.svg');
        }
      });
    })
    .catch(error => console.error(error));
});
